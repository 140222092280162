import * as actionTypes from '../actions/actionType';

const initialState = {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isAuthenticated: false,
    error: null,
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return{
                ...state,
                loading: true,
                isAuthenticated:false
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                expiresIn: action.expiresIn,
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                isAuthenticated: false, //false
                loading: false,
                accessToken: null,
                refreshToken: null,
                expiresIn: null,
                error: action.payload
            }
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                isAuthenticated: false, //false
                loading: false,
                accessToken: null,
                refreshToken: null,
                expiresIn: null,
                error: null
            }
        default:
            return state;
    }
}

export default reducer;